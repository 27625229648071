<template>
    <sign-page
      title-text="正式党员"
      :request="request"
      :column-list="columnList"
      :form-parms-add="formParmsAdd"
      @dataFileUpdate="dataFileUpdate"
      :title-menus="[{key: 'add', label: '新增'}]"
      table-size="large"
      :tableActions="tableActions"
      :init-add-form-data="initAddFormData"
      :form-parms-update="formParmsUpdate"
      :tableActionsFixed="true"
      :need-data-file="true">
    </sign-page>
  </template>
  
  <script>
  import {
    workerCertificateRequest as request
  } from '../../api'
  
  import {
    dateOperating
  } from '@/fmlib'
  
  import {
    loadData,
    getSelectWorkerList,
    loadSelectWorkerList
  } from '../../auth_lib'
  
  export default {
    created () {
      if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
        loadSelectWorkerList(this)
      }
    },
    computed: {
      tableActions () {
        let data = [{
          key: 'data_file',
          label: '附件'
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
        return data
      },
      formParmsAdd: {
        get () {
          let data = [{
            type: 'select',
            selectDatas: getSelectWorkerList(this),
            label: '所属党员',
            key: 'workerId',
            check: {
              required: true
            }
          },
          {
            type: 'datePicker',
            label: '转正时间',
            key: 'hireStartDate'
          }]
          return data
        }
      },
      formParmsUpdate: {
        get () {
          let data = []
          this.formParmsAdd.forEach((item) => {
            let newItem = {}
            for (let key in item) {
              newItem[key] = item[key]
            }
            data.push(newItem)
          })
          return data
        }
      },
      columnList: {
        get () {
          let data = [{
            title: '党员姓名',
            sort: true,
            field: 'workerName'
          },
          {
            title: '转正时间',
            sort: true,
            field: 'hireStartDate',
            filterRange: true,
            dataType: Date,
            render: (h, rowData) => {
              return h('div', rowData && rowData.hireStartDate ? rowData.hireStartDate.slice(0, 10) : '-')
            }
          }]
          return data
        }
      }
    },
    methods: {
      async dataFileUpdate (parm, pageVm) {
        await request.update(pageVm.chooseData.id, {
          imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
          fileIds: parm ? parm.join(',') : ''
        })
        pageVm.loadData()
      },
      initAddFormData () {
        return {type: 'zsdy'}
      },
      async loadData () {
        let parm = {
          type: 'zsdy'
        }
        return await loadData(request, this, parm)
      }
    },
    data () {
      return {
        choose: null,
        loading: false,
        request: Object.assign({}, request, {
          get: this.loadData
        })
      }
    }
  }
  </script>
  